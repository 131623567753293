import type { AppContext, AppProps } from 'next/app';
import { SWRConfig } from 'swr';
import ThemeProvider from '@/components/ThemeProvider';
import GlobalStyles from '@/styles/GlobalStyles';
import { ColorModeProvider } from '@/contexts/ColorModeContext';
import InlineScript from '@/styles/InlineScript';
import '@/public/fonts/Font.css';
import '@/public/css/lexical_editor.scss';
import { RecoilRoot } from 'recoil';
import { INIT_MY_INFO } from '@/const/initData';
import { MyInfoState } from '@/recoil/atom';
import SeoHeader from '@/components/SeoHeader';
import '@fortawesome/fontawesome-svg-core/styles.css';
import LoadingLottie from '@/components/LoadingLottie';
import { useEffect, useState } from 'react';
import useHotjar from 'react-use-hotjar';
import process from 'process';
import { useRouter } from 'next/router';
import { I18nextProvider } from 'react-i18next';
import i18n from '../public/utils/i18n';
import { CustomDialogProvider } from '@/components/CustomDialog/CustomDialogProvider';
import { AuthProvider } from '@/auth/AuthContext';
import { LocalizationProvider } from '@mui/x-date-pickers';
// If you are using date-fns v2.x, please import `AdapterDateFns`
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { CustomToastProvider } from '@/components/CustomToast/CustomToastProvider';
// If you are using date-fns v3.x, please import the v3 adapter

const myCustomLogger = console.log;

const App = ({ Component, pageProps }: AppProps<AppContext>) => {
  const [loading, setLoading] = useState(true);
  const [opacity, setOpacity] = useState<0 | 1>(1);
  const router = useRouter();
  const needLoading =
    router.route === '/' ||
    router.route === '/login' ||
    router.route === '/m' ||
    router.route === '/m/sales' ||
    router.route === '/m/mypage' ||
    router.route === '/m/organization' ||
    router.route === '/m/approval';

  const { initHotjar } = useHotjar();

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2300); // 로딩 애니메이션이 최소 1회는 전부 출력되도록 하기 위한 딜레이
    setTimeout(() => {
      setOpacity(0);
    }, 1800); // 로딩 애니메이션이 자연스럽게 사라지도록 하기 위한 딜레이
  }, []);

  useEffect(() => {
    initHotjar(
      process.env.NEXT_PUBLIC_ORIGIN_URL === 'https://portal.fnf.co.kr'
        ? 3730425
        : 3854712,
      6,
      false,
      myCustomLogger,
    );
  }, [initHotjar]);

  return (
    <>
      <SeoHeader />
      <AuthProvider>
        <SWRConfig>
          <ColorModeProvider>
            <InlineScript />
            <ThemeProvider>
              <GlobalStyles />
              <I18nextProvider i18n={i18n}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <RecoilRoot
                    initializeState={({ set }) =>
                      set(MyInfoState, INIT_MY_INFO)
                    }
                  >
                    <CustomDialogProvider>
                      <CustomToastProvider>
                        {needLoading && loading && (
                          <LoadingLottie opacity={opacity} />
                        )}
                        <Component {...pageProps} />
                      </CustomToastProvider>
                    </CustomDialogProvider>
                  </RecoilRoot>
                </LocalizationProvider>
              </I18nextProvider>
            </ThemeProvider>
          </ColorModeProvider>
        </SWRConfig>
      </AuthProvider>
    </>
  );
};

export default App;
