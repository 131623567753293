import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function TextField(theme: Theme) {
  return {
    MuiTextField: {
      defaultProps: {
        InputProps: {
          style: {
            height: '44px',
            padding: 0,
          },
        },
      },
      styleOverrides: {
        root: {
          // height: '44px',
        },
      },
    },
  };
}
