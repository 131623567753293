import { Theme } from '@emotion/react';
import { CommonColors } from '@/types/theme';

const colors: CommonColors = {
  blue: '#007bff',
  indigo: '#6610f2',
  purple: '#6f42c1',
  pink: '#e83e8c',
  red: '#dc3545',
  orange: '#fd7e14',
  yellow: '#ffc107',
  green: '#28a745',
  teal: '#20c997',
  cyan: '#17a2b8',
  white: '#ffffff',
  whiteHover: '#d9d9d9',
  gray: '#7E8299',
  lightgray: '#d1d3e0',
  grayDark: '#3F4254',
  lightPrimary: '#e1f0ff',
  primary: '#3699FF',
  primaryHover: '#0073e9',
  secondary: '#E4E6EF',
  secondaryHover: '#b4bad3',
  success: '#1BC5BD',
  successHover: '#12827c',
  info: '#8950FC',
  infoHover: '#5605fb',
  warning: '#FFA800',
  warningHover: '#b37600',
  lightDanger: '#fddbdf',
  danger: '#F64E60',
  dangerHover: '#ec0c24',
  light: '#F3F6F9',
  lightHover: '#c0d0e0',
  dark: '#181C32',
  dark90: '#282f48',
  dark75: '#3F4254',
  dark65: '#5e6278',
  dark50: '#7e8299',
  dark25: '#d1d3e0',
  mute: '#b5b5c2',
  buttonInActive: '#1E1E2D',
  textPrimary: '#3F4254',
  textSecondary: '#B5B5C3',
  black12: 'rgba(0, 0, 0, 0.12)',
  black50: 'rgba(0, 0, 0, 0.5)',
  white50: 'rgba(255, 255, 255, 0.5)',
};

const lightTheme: Theme = {
  mode: 'light',
  colors: colors,
};

const darkTheme: Theme = {
  mode: 'dark',
  colors: colors,
};

const COLOR_MODE_KEY = 'color-mode';
const INITIAL_COLOR_MODE_CSS_PROP = '--initial-color-mode';

const themeProperties = {
  'primary-color': {
    light: '#1E1E2D',
    dark: '#1E1E2D',
  },
  'mode-color': {
    light: lightTheme.mode,
    dark: darkTheme.mode,
  },
  'card-backgound-color': {
    light: colors.white,
    dark: colors.dark90,
  },
  'text-primary-color': {
    light: colors.textPrimary,
    dark: 'white',
  },
  'text-secondary-color': {
    light: colors.textSecondary,
    dark: colors.dark50,
  },
  'text-third-color': {
    light: colors.black50,
    dark: colors.white50,
  },
  'button-background-color': {
    light: colors.light,
    dark: colors.dark,
  },
  'background-color': {
    light: '#f7f7f7',
    dark: '#f7f7f7',
  },
  'border-color': {
    light: '#eaeaea',
    dark: '#222',
  },
  'icon-color': {
    light: colors.textSecondary,
    dark: colors.lightgray,
  },
  'icon-color-light': {
    light: colors.dark25,
    dark: colors.light,
  },
  'icon-bg': {
    light: '#e2e2e7',
    dark: '#e2e2e7',
  },
  'body-color': {
    light: colors.white,
    dark: colors.dark,
  },
};

export {
  lightTheme,
  darkTheme,
  themeProperties,
  COLOR_MODE_KEY,
  INITIAL_COLOR_MODE_CSS_PROP,
};
