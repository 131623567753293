import React, {
  createContext,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useState,
} from 'react';
import { AlertProps, SnackbarProps } from '@mui/material';
import CustomToast from '@/components/CustomToast/index';

type SnackbarOptions = {
  severity: AlertProps['severity'];
  message: React.ReactNode;
  duration?: number | null;
  position?: SnackbarProps['anchorOrigin'];
  onClose?: () => void;
};

export interface SnackbarContextProps {
  show: (options: SnackbarOptions) => void;
  showError: (options: Omit<SnackbarOptions, 'severity'>) => void;
}

export const SnackbarContext = createContext<SnackbarContextProps | undefined>(
  undefined,
);

export const CustomToastProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [message, setMessage] = useState<ReactNode>();
  const [snackbarProps, setSnackbarProps] = useState<SnackbarProps>();
  const [iconProps, setIconProps] = useState<AlertProps>();

  const show = useCallback(
    ({
      message,
      severity,
      duration = 2000,
      position = {
        vertical: 'top',
        horizontal: 'right',
      },
      onClose,
    }: SnackbarOptions) => {
      setMessage(message);

      const handleClose = () => {
        onClose?.();
        setSnackbarProps({
          open: false,
        });
      };

      setSnackbarProps({
        open: true,
        autoHideDuration: duration,
        onClose: handleClose,
        anchorOrigin: position,
      });

      setIconProps({
        severity,
        onClose: handleClose,
      });
    },
    [],
  );

  const showError = useCallback(
    (options: Omit<SnackbarOptions, 'severity'>) => {
      show({
        ...options,
        severity: 'error',
      });
    },
    [show],
  );

  return (
    <SnackbarContext.Provider value={{ show, showError }}>
      <CustomToast
        message={message}
        iconProps={iconProps}
        snackbarProps={snackbarProps}
      >
        {children}
      </CustomToast>
    </SnackbarContext.Provider>
  );
};
