import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import { createSlice } from '@reduxjs/toolkit';
import { MyInfo, PortalUserResponse } from '@/types/api';
import { fetchApi } from '@/utils/api/customAxios';
import { getCookie } from '@/utils/tokens';
import { JWT_ACCESS_TOKEN } from '@/const/tokens';
import { MYINFO_URL, PORTAL_PASSPORT_URL } from '@/const/apiList';

export const AuthContext = createContext<AuthContextType | null>(null);

type AuthProviderProps = {
  children: React.ReactNode;
};

type AuthContextType = {
  method: string;
  isInitialized: boolean;
  isLoading: boolean;
  error: Error | string | null;
  myInfo: MyInfo;
  isGuest: boolean;
};

export type AuthStateType = {
  isInitialized: boolean;
  isLoading: boolean;
  error: Error | string | null;
  myInfo: MyInfo;
  isGuest: boolean;
};

const initialState: AuthStateType = {
  isInitialized: false,
  isLoading: false,
  error: null,
  myInfo: {
    employeeNumber: '',
    name: '',
    nickname: '',
    email: '',
    positionName: '',
    positionCode: '',
    departmentName: '',
    departmentCode: '',
    divisionName: '',
    divisionCode: '',
    companyCode: '',
    companyName: '',
    organizationFullName: '',
    organizationShortName: '',
    profileImageUrl: '',
    phoneInside: '',
    phoneMobile: '',
    birthdayType: 0,
    birthday: '',
    userId: '',
    passportId: '',
    enterDate: '',
    language: '',
    joinedAt: '',
    isLeader: false,
  },
  isGuest: true,
};

const useToolKitReducer = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    initialSuccess(state, action) {
      state.isInitialized = true;
      state.isLoading = false;
      state.myInfo = action.payload.myInfo;
      state.isGuest = action.payload.isGuest;
    },
  },
});

export function AuthProvider({ children }: AuthProviderProps) {
  const { getInitialState, reducer, actions } = useToolKitReducer;
  const [state, dispatch] = useReducer(reducer, getInitialState());

  // 첫 페이지 랜더링시
  const _initialize = useCallback(async () => {
    dispatch(actions.startLoading());

    const accessToken = getCookie(JWT_ACCESS_TOKEN);
    //console.log('accesToken', accessToken);

    if (accessToken) {
      const portlUserInfo = await fetchApi<{ data: PortalUserResponse }>({
        method: 'GET',
        url: PORTAL_PASSPORT_URL,
        extraHeaders: {
          Authorization: `Bearer ${getCookie(JWT_ACCESS_TOKEN)}`,
        },
      });

      const myInfo = await fetchApi<{ data: MyInfo }>({
        method: 'GET',
        url: MYINFO_URL,
      });

      dispatch(
        actions.initialSuccess({
          myInfo: {
            ...portlUserInfo.data.defaultPassport.detail,
            passportId: portlUserInfo.data.defaultPassport.passportId,
            userId: myInfo.data.userId,
            enterDate: myInfo.data.enterDate,
            language: portlUserInfo.data.personalization.lang,
          },
          isGuest: false,
        }),
      );
    } else {
      dispatch(
        actions.initialSuccess({
          myInfo: initialState.myInfo,
          isGuest: true,
        }),
      );
    }
  }, [actions]);

  useEffect(() => {
    if (_initialize) {
      _initialize();
    }
  }, [_initialize]);

  const memoizedValue = useMemo(
    () => ({
      method: 'auth',
      ...state,
    }),
    [state.isInitialized, state.isLoading, state.error],
  );

  return (
    <AuthContext.Provider value={memoizedValue}>
      {children}
    </AuthContext.Provider>
  );
}
