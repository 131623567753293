import { alpha, Theme } from '@mui/material/styles';
import { ChipProps } from '@mui/material';
import { ChipPropsVariantOverrides } from '@mui/material/Chip';

// ----------------------------------------------------------------------

const COLORS = [
  'primary',
  'secondary',
  'info',
  'success',
  'warning',
  'error',
] as const;

// NEW VARIANT
declare module '@mui/material/Chip' {
  interface ChipPropsVariantOverrides {
    soft: true;
  }
}

export default function Chip(theme: Theme) {
  const rootStyle = (ownerState: ChipProps) => {
    const defaultColor = ownerState.color === 'default';
    const filledVariant = ownerState.variant === 'filled';
    const outlinedVariant = ownerState.variant === 'outlined';
    const softVariant = ownerState.variant === 'soft';

    const defaultStyle = {
      ...((filledVariant || outlinedVariant || softVariant) && {
        borderRadius: '4px',
        padding: '2px 8px',
        height: 'auto',
        fontSize: '12px',
        lineHeight: '18px',
        cursor: 'inherit',
        '&:hover': {},
        '& .MuiChip-label': {
          padding: 0,
        },
      }),
      ...(defaultColor && {
        ...(outlinedVariant && {
          border: `solid 1px ${alpha(theme.palette.grey[500], 0.32)}`,
        }),
        ...(filledVariant && {
          backgroundColor: 'rgba(243, 245, 246, 1)',
          border: 'none',
          color: '#000000',
        }),
        ...(softVariant && {
          backgroundColor: 'rgba(231, 233, 236, 0.32)',
          border: '1px solid #E7E9EC',
          color: '#000000',
        }),
      }),
    };

    const colorStyle = COLORS.map((color) => ({
      ...(ownerState.color === color && {
        // FILLED
        ...(filledVariant && {
          '&.MuiChip-colorPrimary': {
            backgroundColor: '#000',
            border: 'none',
            color: '#FFFFFF',
          },
          '&.MuiChip-colorError': {
            backgroundColor: '#F55060',
            border: 'none',
            color: '#FFFFFF',
          },
          '&.MuiChip-colorSuccess': {
            backgroundColor: 'rgba(67, 177, 111, 1)',
            border: 'none',
            color: '#FFFFFF',
          },
          '&.MuiChip-colorWarning': {
            backgroundColor: '#FEAE00',
            border: 'none',
            color: '#FFFFFF',
          },
        }),
        // SOFT
        ...(softVariant && {
          '&.MuiChip-colorPrimary': {
            backgroundColor: 'rgba(67, 111, 177, 0.12)',
            border: '1px solid rgba(67, 111, 177, 0.24)',
            color: '#436FB1',
          },
          '&.MuiChip-colorError': {
            backgroundColor: 'rgba(245, 80, 96, 0.12)',
            border: '1px solid rgba(245, 80, 96, 0.24)',
            color: '#F55060',
          },
          '&.MuiChip-colorSuccess': {
            backgroundColor: 'rgba(67, 177, 111, 0.12)',
            border: '1px solid rgba(67, 177, 111, 0.24)',
            color: '#43B16F',
          },
          '&.MuiChip-colorWarning': {
            backgroundColor: 'rgba(254, 174, 0, 0.12)',
            border: '1px solid rgba(254, 174, 0, 0.24)',
            color: '#FEAE00',
          },
        }),
      }),
    }));

    return [defaultStyle, ...colorStyle];
  };

  return {
    MuiChip: {
      styleOverrides: {
        root: ({ ownerState }: { ownerState: ChipProps }) =>
          rootStyle(ownerState),
      },
    },
  };
}
